import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/mobiledesign';
import ProjectSection from '../components/ProjectSection';
import competitors from '../images/gizmopal/gizmopal_competitors.png';
import app from '../images/gizmopal/gizmopal_app.png';
import calling from '../images/gizmopal/gizmopal_calling.png';
import pairing from '../images/gizmopal/gizmopal_pairing.png';
import pcmag from '../images/gizmopal/gizmopal_pcmag.png';
import time from '../images/gizmopal/gizmopal_time.png';
import colors from '../images/gizmopal/gizmopal_colors.png';
import trial from '../images/gizmopal/gizmopal_trial.png';
import '../styles/css/ProjectSection.css';

class GizmoPal extends Component {
    render() {
        const projectID = 2;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/mobiledesign/outofbox" next="/mobiledesign/moregizmos" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Challenge"
                    variant="colCopyStart"
                    hasImage={false}
                    body="Myself and four others, three technology experts and one product manager, were provided with a product that was previously launched in Korea. The hardware needed to be reused, but the software for the device and the companion app could be redesigned if we decided it was necessary. How do you take a product and redesign it for the needs of the US market in 5 months? "
                />

                <ProjectSection
                    title="Looking at the Competitive Landscape"
                    variant="rowCopyStart"
                    hasImage
                    image={competitors}
                    imageAlt="competitive review"
                    body="To learn more about wearable technology for kids, I did a competitive assessment of over 20 devices, including an in-depth depth assessment of the two most similar to the product I was working on. I helped to plan and take notes for a series of focus groups that my co-worker ran with parents and grandparents to get feedback on the product concept. We also usability tested the existing product prior to making any design changes. Based on the research, I worked with the rest of the core team to map out customer needs and prioritize product changes required to make the device successful in the US.  "
                />
                <ProjectSection
                    title="Redesigning the Device"
                    variant="rowCopyStart"
                    hasImage
                    image={time}
                    imageAlt="playing the time on device"
                    body="The device only had one button, but it was supposed to be able to play fun sounds, call two people, read out the time, turn on and off, and factory reset. I had to design how one button could do all of these functions. I also had to define how the light around the button and voice messages could help the customer understand what was happening. Overloading a button is risky business and it’s often difficult to discover all of the different functionalities. To test my design decisions, I need to frequently test with kids and parents, so I enlisted the help of other employees with kids aged 3 to 7 for quick usability testing. I was fortunately able to work closely with the device manufacturer to do on device A/B testing for things like using double press vs long press. "
                />

                <img src={calling} alt="making a call on device" className="project-extra-img project-section-content" />

                <ProjectSection
                    title="Designing a New App"
                    variant="colCopyStart"
                    hasImage
                    image={pairing}
                    imageAlt="how to pair with phone"
                    body="The way the device worked was going to prevent the product from supporting parents with iPhones and the experience with Android phones was pretty confusing, so the technical leads came up with a solution to allow for more flexibility. However the solution meant creating a new Android app and an iOS app. The high priority features the app needed to provide were allowing a parent’s phone to pair with the device, locating the device, viewing the device location on a map, calling the device, and managing the device settings. I provided the app wireframes and the visual design for the project. "
                />

                <img src={app} alt="using the app" className="project-extra-img project-section-content" />
                <img src={colors} alt="customizing device" className="project-extra-img project-section-content" />

                <ProjectSection
                    title="Working with Development"
                    variant="colCopyStart"
                    hasImage={false}
                    body="To meet a tight timeline, I worked very closely with the development vendor. Even though we weren’t co-located, there was a lot of collaboration. We communicated constantly throughout the day to keep the process running smoothly.  I was answering questions on the designs, making updates as needed, and reviewing prototyped interactions. I was also testing daily builds of software, providing feedback on how the design was implemented, and documenting bugs.  "
                />

                <ProjectSection
                    title="Running an Internal Trial"
                    variant="colCopyStart"
                    hasImage
                    image={trial}
                    imageAlt="getting started guide"
                    body="As we got to the final stages of the project, we organized a three week trial with 25 parents who had children in target age range, to get more feedback on using the device. I created testing guides similar to the final go to market materials to introduce the product and test our in-box materials. This was the first trial of this kind and it was extremely valuable. We continued to make small changes based on the feedback and started planning for enhancements to make after the launch. "
                />

                <ProjectSection
                    title="Launching the Product"
                    variant="colCopyStart"
                    hasImage
                    image={pcmag}
                    imageAlt="pc mag review"
                    body="The last few weeks leading up to launch was an extremely busy time. This project had very little resources assigned to it, because it was a new type of device, so all five members of core team worked long hours to make it happen. The device launched on time, just before the holiday season. To ensure the success of the product, the core team set up and monitored a product specific support email to quickly provide customers with help. The mobile app also included a send feedback option, that would directly go to the same email. We knew our typical customer support line might struggle providing support for a new device type, so we wanted to avoid customers calling customer support. We also tracked and responded to app reviews, and I led an effort to interview customers that purchased the device. We used this feedback to make app updates, improve our in-app help, make device updates, and plan for a next generation gizmo. This project proved to me that small teams within big companies can operate like lean startups and bring products to market quickly. "
                />

            </div>
        );
    }
}

export default GizmoPal;