import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/mobiledesign';
import ProjectSection from '../components/ProjectSection';
import competitors from '../images/sureresponse/sureresponse_competitors.png'
import concept from '../images/sureresponse/sureresponse_concept.png'
import web from '../images/sureresponse/sureresponse_web.png'
import uxflow from '../images/sureresponse/sureresponse_uxflow.png'
import care from '../images/sureresponse/sureresponse_care.png'

class SureResponse extends Component {
    render() {
        const projectID = 0;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/mobiledesign/sureresponse" next="/mobiledesign/outofbox" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Challenge"
                    variant="rowCopyStart"
                    hasImage={false}
                    body="Create a simple product, similar to Lifeline that meets the needs of a growing senior population that wants to remain independent and active, but still have access to help when they need it. I reviewed competitor devices to better understand how products like Lifeline do a great job providing this service in the home, but can't support customers when they're out for a walk or picking up groceries. How do you take a concept and turn it into a full featured service?" />
                <ProjectSection
                    title="Understanding the Goals"
                    body="When I joined this project, there was a product development lead and product management lead and a very long service requirements document. The concept was clear, but the reason for creating the service was a little unclear. Why not just give this person a cell phone with a 911 button?
                    What I learned was that the senior market was growing at a rapid rate, because of the baby boomer population. There was a lot of focus on making smarter phones, but maybe not everyone's needs can be met with a smartphone or a basic phone.e"
                    variant="rowCopyEnd"
                    hasImage
                    image={competitors}
                    imageAlt="competitor devices" />
                    
                <ProjectSection
                    title="Testing the Concept"
                    variant="rowCopyStart"
                    hasImage
                    image={concept}
                    imageAlt="initial concept"
                    body="I created a few simple drawings to use for explaining the concept and my co-worker created a 3D rendering to help me explain what the product might look like. After that I worked with our recruiter to set up one-on-one interviews to ask prospective customers what they thought about the concept. I asked if they would use it and why or why not. I asked what they liked and didn't like. It was a great experience doing conceptual research and it was very enlightening talking to seniors about technology."
                />
                <ProjectSection
                    title="Refining the Concept"
                    variant="rowCopyStart"
                    hasImage={false}
                    body="The customers I spoke with provided a lot of insight into a population that I had never worked with before. It really was a strong reminder for a younger designer how important learning about your customer is. I have 20/20 vision, so it's easy to over look font size being too small, but every single person I interviewed took out reading glasses the moment I handed them the participant consent form. Cost is a big concern for many customers, but even bigger for a customer that is on a fixed income like many seniors living on pensions or just Social Security benefits. Lastly, the perception of needing an emergency device was very scary to the people I spoke with. They thought it was a great idea, but they didn't want anyone to think they needed that kind of support. Staying independent was essential and wearing a device with a big red cross was the last thing they wanted to do.

                    I went back to the drawing board to revamp the concept and focus on keeping the device easy to use, but rethink the emergency device image."
                />
                <ProjectSection
                    title="Building the Product"
                    variant="colCopyEnd"
                    hasImage
                    image={web}
                    imageAlt="tracking dashboard"
                    body="This project had a lot of moving parts. There was the mobile device, the dock, the base software of the device and dock, the location tracking software, the location tracking web dashboard and an external call center. I had to provide guidance for the whole experience. I created web dashboard wireframes and defined device functionality."
                />
                <ProjectSection
                    title="Going from UI to UX"
                    variant="colCopyStart"
                    hasImage
                    image={uxflow}
                    imageAlt="ux flow"
                    body="All of the project pieces needed to be brought together to build not just a product, but a service. Working closely with the vendors involved helped me learn about what it really takes to go from requirements to solution. Nothing went smoothly or as planned, but it was the first product I was able to craft a full experience and not just pixels on a screen. I had to think about the customer journey from before purchase to after months of usage and create storyboards like the one above showing the initial setup experience. It was a shift from user interface design to user experience design."
                />
                <ProjectSection
                    title="The Outcome"
                    variant="colCopyEnd"
                    hasImage
                    image={care}
                    imageAlt="customer support documentation"
                    body="I learned a lot working on SureResponse. It was my first user experience project and my first wearable project. The project started with a wish list of requirements, but by focusing on customer needs, myself and the product team were able to bring a new type of product to the market. To assist in going to market, I had to create materials for customers, but also materials for internal teams to help them quickly get up to speed, so they could properly support customers."
                />

            </div>
        );
    }
}

export default SureResponse;