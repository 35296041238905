import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import '../styles/css/FeaturedWork.css';
import { withStyles } from '@material-ui/core/styles'; //needed to override styles
import linkedin from '../images/linkedin.svg';
import coffee from '../images/coffee.png';
import EmailIcon from '@material-ui/icons/Email';
import { Button } from '@material-ui/core';


function sendEmail() {
    window.location = "mailto:dlynsh01@gmail.com";
}

function goToLinkedIn() {
    window.location = "https://www.linkedin.com/in/deniselynshue/";
}

const styles = {
    mainFeaturedPost: {
        backgroundColor: grey[800],
        color: "#FFFFFF",
        marginBottom: 16,
        marginTop: 24,
        padding: 16,

    },
    contactImage: {
        height: 100,
        paddingLeft: 8,
        paddingRight: 8
    },
    imageContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    leftIcon: {
        marginRight: 8,
    },
    button: {
        marginRight: 8,
    }
};

const ContactCard = (props) => {
    const { classes } = props;
    return (
        <div>
            <Paper className={classes.mainFeaturedPost}>
                <Grid container>
                    <Grid item sm={9} md={8} lg={9}>
                        <div >
                            <Typography component="h1" variant="h5" color="inherit" gutterBottom>
                                Contact Me
                            </Typography>
                            <Typography variant="subtitle1" color="inherit" paragraph>
                                I'm always interested in getting coffee, meeting new people, and chatting about design and technology.
                                Send me an email or connect with me on LinkedIn.
                            </Typography>
                            <Button variant="text" color="primary" className={classes.button} onClick={sendEmail}>
                                <EmailIcon className={classes.leftIcon} />
                                Email Me
                            </Button>
                            <Button variant="text" color="primary" className={classes.button} onClick={goToLinkedIn}>
                                <img alt="LinkedIn" src={linkedin} className={classes.leftIcon} />
                                Connect with Me
                            </Button>
                        </div>
                    </Grid>
                    <Grid className={classes.imageContainer} item sm={3} md={4} lg={3}>
                        <Hidden xsDown>
                            <img className={classes.contactImage} alt="Coffee" src={coffee} />
                        </Hidden>
                    </Grid>
                </Grid>
            </Paper>
        </div >
    );
}

export default withStyles(styles)(ContactCard);