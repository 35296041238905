import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import '../styles/css/ProjectStepper.css'

const styles = {
    root: {
        maxWidth: 800,
        flexGrow: 1,
    },
};

class ProjectStepper extends Component {
    state = {
        activeStep: this.props.starting,
    };

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    render() {
        const { classes, theme } = this.props;

        return (
            <div className="stepper-container">
                <MobileStepper
                    variant="dots"
                    steps={this.props.steps}
                    position="static"
                    activeStep={this.state.activeStep}
                    className={classes.root}
                    nextButton={
                        <Button component={Link} to={this.props.next} size="small" onClick={this.handleNext} disabled={this.state.activeStep === this.props.steps - 1} >
                            Next Project
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button component={Link} to={this.props.previous} size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0} >
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            Previous Project
                        </Button>
                    }
                />
            </div>
        );
    }
}

ProjectStepper.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ProjectStepper);