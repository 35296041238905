import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import '../styles/css/ProjectCard.css';
import { Button, CardActions } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';


const PrivateProjectCard = (props) => {

    return (

        <Grid item sm={6} md={4} lg={3}>
            <Card className="card-project">
                <CardMedia className="card-project-media"
                    component="img"
                    height="300"
                    image={props.image}
                    title={props.title}
                />
                <CardContent className="card-project-details">
                    <div className="card-project-title">
                        <LockIcon color="inherit" style={{ fontSize: 18, marginRight: 8 }} />

                        <Typography gutterBottom variant="h5" component="h2">
                            {props.title}
                        </Typography>

                    </div>

                    <Typography>
                        {props.summary}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" color="primary" onClick={props.showLogin} >
                        View Project Details
                    </Button>
                </CardActions>
            </Card>
        </Grid>

    );
}

export default PrivateProjectCard;