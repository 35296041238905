import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/healthcaredesign';
import ProjectSection from '../components/ProjectSection';
import allinfo from '../images/patientsummary/ptab_all.png';
import concept from '../images/patientsummary/ptab_concept.png';
import prototype from '../images/patientsummary/ptab_prototype.png';
import largeview from '../images/patientsummary/ptab-large.png';

class PatientSummary extends Component {
    render() {
        const projectID = 0;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/healthcaredesign/patientsummary" next="/healthcaredesign/providerperformance" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Problem"
                    variant="colCopyStart"
                    hasImage={false}
                    body="Providers caring for patients that are part of a risk contract and possibly enrolled in care management, had no way to quickly gain insight about their patients’ care management activities or hospital visits outside of their network. Having access to more information like care management event notes and claims based information about utilization of medical services could allow them to provide better care and reinforce the care team’s goals with the patient. It also would allow them to pick up on possible issues like patients going to the emergency room often or getting readmitted to the hospital, but not reporting those visits to their primary care provider."
                />

                <ProjectSection
                    title="Cross Product Initiative"
                    variant="colCopyStart"
                    hasImage={false}
                    body="This was my first larger project at athenahealth and it involved working with teams supporting other products. I was primary focused on our Population Health product, but to successfully surface more information to providers, I needed to work closely with folks working on our electronic health records product. The high-level plan was to put a link to a Population Health patient summary within the EHR for our clients that used both products, so a provider could access the information in context to the patient’s chart and without navigating away from the chart. This required a lot of sharing, planning, and testing across product teams."
                />

                <ProjectSection
                    title="Finding a Starting Point"
                    variant="rowCopyStart"
                    hasImage
                    image={allinfo}
                    imageAlt="all of the info"
                    body="There was no existing patient summary in the Pop Health product, so I started reviewing the available information to hypothesize what was the most important information and should be part of the summary. I looked at related patient documents that could be downloaded, information on the landing screen for a patient, and custom reports available in Pop Health. I started with a lot of information, which was too much for a summary, so I worked with internal subject matter experts to start to prioritize content in preparation for getting early designs in front of clients. "
                />

                <ProjectSection
                    title="Collecting Client Feedback"
                    variant="rowCopyStart"
                    hasImage
                    image={concept}
                    imageAlt="concept designs"
                    body="With the help of my product team and client account managers, I was able to set up several calls with clients. We were able to speak to a few clients multiple times, which was helpful in showing clients how we incorporated their feedback and addressed any issues they brought up. After each feedback session, I would gather with the product owner and discuss the feedback and make changes to the designs as needed. It was a very iterative process and we made may tweaks along the way, until we felt confident about the design and clients expressed excitement in using the new functionality. Based on the positive client reactions, the product leadership team also expressed interest in integrating the functionality into other EHRs as another way to provide value to clients that used our Pop Health product, but not our EHR. "
                />

                <ProjectSection
                    title="Prototyping"
                    variant="rowCopyStart"
                    hasImage
                    image={prototype}
                    imageAlt="small screen"
                    body="Due to the design needing to be flexible and easily dropped into an unknown window size of possibly any EHR in the future, I wanted to make sure the design was responsive and scalable. It needed to fit different lengths of content, because event notes could go from a few works for thousands of words. The window it was being placed in, could be the size of a mobile phone or full screen on a large monitor. To help with illustrating that the design would adapt, I built a prototype in code, so I could resize the browser, change the content, and see where and how it might break. It was more time consuming that building out static screens in Sketch, but it was very helpful in designing a scalable screen and also in explaining the behavior to my developers. My developers were in Austin, Texas at the time, so sharing a prototype that they could play with and see the styling changes helped us overcome the barrier of being in different offices. They also made quick tweaks to the prototype to show other possible options or highlight issues, so it gave us a way to communicate on the details quickly without needing to be in the same room."
                />

                <ProjectSection
                    title="The Result"
                    variant="colCopyStart"
                    hasImage
                    image={largeview}
                    imageAlt="large screen"
                    body="At the end of the project, we were able to launch an alpha and continue collecting feedback. Eventually we were able to turn the functionality on for all applicable clients. Overall, clients were happy with the summary information provided, but wanted to be able to dig a little deeper. Clients wanted to be able to view the dates and locations of all out-of-network emergency room visits and hospital admits, so they could contact those locations if needed and to help them identify any patients exhibiting drug seeking behaviors. Apparently it is common for opioid addicts to visit hospitals further away from their home when trying to get more drugs. "
                />

            </div>
        );
    }
}

export default PatientSummary;