import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import '../styles/css/MobileProducts.css';

class Project extends Component {
    render() {
        return (
            <div >
                <div className="hero-mobile">
                    <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                        {this.props.title}
                    </Typography>
                    <Typography variant="h6" align="center" color="textSecondary" paragraph>
                        {this.props.summary}
                    </Typography>
                </div>
            </div>
        );
    }
}

export default Project;