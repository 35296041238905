import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/mobiledesign';
import ProjectSection from '../components/ProjectSection';
import '../styles/css/ProjectSection.css';
import complexpath from '../images/activation/complexpath.png';
import legalreview from '../images/activation/legalreview.png';
import happypath from '../images/activation/happypath.png';
import ondevice from '../images/activation/ondevice.png';
import highlevel from '../images/activation/highlevel.png';

class SmartWatchActivation extends Component {
    render() {
        const projectID = 5;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/mobiledesign/smartwatchcalling" next="/mobiledesign/smartwatchactivation" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Challenge"
                    variant="colCopyStart"
                    hasImage={false}
                    body="I had previously done a lot of work around activating new smartphones and other wearable devices, but smartwatches initially only needed bluetooth pairing and the process was handled entirely by the operating system. With the addition of adding mobile service to watches, there was a need to activate them on the mobile network. The first connected watches to launch, used an activation process designed for flip phones, where the customer would have previously added the device to their plan by calling, going online, or going to a store. When the customer received the device, they would dial an activation number and wait for some signal to be sent back to the device completing the activation. This was a good start, but it required deciding to add service when you purchased the device or to contact the mobile carrier prior to adding service. We were moving away from the technology used for this activation towards something similar to our smartphone activations and we had new requirements for using universal SIMs instead of carrier branded SIMs to allow improved network interoperability. How do we make activation easier, while leveraging the latest network technology, and following the requirements for universal SIMs?"
                />

                <ProjectSection
                    title="Learning About New Technology"
                    variant="colCopyStart"
                    hasImage
                    image={highlevel}
                    imageAlt="high level flow"
                    body="My first step was to learn more about the technology and get involved in the design of how we were architecting our technical solution. For some, this might not seem like the place for a designer to get involved, but I believed getting into the details was necessary. If you are not providing a voice for the customer at the very start of a project, then you might end up with large barriers to meeting the customer’s needs, as you get into designing the parts of the system the customer will directly interact with. I participated in a lot technical discussions and started each one by explaining that I was there to learn about the technology and to help set the technology up to meet the needs of the customer. The result of my collaborations with the team on technology and the designs we were worked on together, not only produced a new method for activation, but also a US Patent (System and method for EUICC personalization and network provisioning, US 10111063)."
                />
                <ProjectSection
                    title="Aligning Stakeholders"
                    variant="colCopyStart"
                    hasImage
                    image={happypath}
                    imageAlt="detailed path design"
                    body="Once we started working on the customer workflows for activation, there were a lot of interested parties. At a mobile carrier, activation is a big deal and impacts a lot of other teams. I started creating easy to follow documentation that I could share with teams and also hosting recurring status meetings to make sure all stakeholders were well informed and had their chance to voice any issues or concerns early. Frequently updating documentation and hosting additional meetings was added work, but it kept the project moving smoothly and also reduced churn and scope creep from late breaking issues requiring additional work. It also was a way to pressure test some of the designs, because what I started out with was a simple happy path and eventually I ended up with very detailed designs for various happy paths and error situations. "
                />

                <img src={complexpath} alt="additional detailed path" className="project-extra-img project-section-content" />


                <ProjectSection
                    title="Security and Experience Can Work Together"
                    variant="colCopyStart"
                    hasImage
                    image={legalreview}
                    imageAlt="account owner phone flow"
                    body="One of the biggest ways I was able to influence the experience was by removing security barriers that didn’t actually improve security or reduce fraud. My plans for the activation, were to allow a customer to leverage information on their phone to quickly complete activation on their watch. Initially, this was going to require a customer to log in using a watch or complete the process on another device by entering the watches information, which wasn’t ideal. I proposed that the customer was already bluetooth paired with their phone, so we could leverage the proximity of the phone as proof the customer had access to both devices. We would have to remind the user to make sure they were connected to their phone and their phone was unlocked. We could then leverage the carrier account on the phone to determine if the customer was the owner of the account and had permission to add a device, from there we could simply allow the customer to agree on the watch, and provide the simple fall back of allowing the customer to undo the action later. We could also use the same process and allow others on a family plan to request permission from the account owner, to add their watch to the family plan using a text message. Once the account owner replied yes, the watch would be added to the account and the user would get notified. This meant we could avoid additional sign ins and security hoops by using a two-factor authentication that required the customer to do very little and we could avoid fraud, by allowing a customer to easily reverse the action. It took many meetings with legal and compliance folks, but eventually we were able to move forward. "
                />

                <ProjectSection
                    title="The Outcome"
                    variant="colCopyStart"
                    hasImage
                    image={ondevice}
                    imageAlt="flow working on device"
                    body="We were able to launch a completely new way to activate connected devices that only required a few steps and didn’t involve calling or going to a store. The idea was to make it simple and delightful to activate a device. If the goal was to get more devices on the network, then the process needed to be a great experience. The new process I designed would set a precedent for future devices including the first connected Apple watch, Samsung Gear devices, and multiple Android Wear devices that I would eventually work on. "
                />

            </div>
        );
    }
}

export default SmartWatchActivation;