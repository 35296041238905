import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/mobiledesign';
import ProjectSection from '../components/ProjectSection';
import '../styles/css/ProjectSection.css';
import diff from '../images/gizmos/gizmos_dif.png';
import diff2 from '../images/gizmos/gizmos_dif2.png';
import messaging from '../images/gizmos/gizmos_msg.png';
import pairing from '../images/gizmos/gizmos_pairing.png';
import pcmag from '../images/gizmos/gizmos_pcmag.png';
import app from '../images/gizmos/gizmos_app.png';
import setup from '../images/gizmos/gizmo_setup.png';

class MoreGizmos extends Component {
    render() {
        const projectID = 3;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/mobiledesign/gizmopal" next="/mobiledesign/smartwatchcalling" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Challenge"
                    variant="colCopyStart"
                    hasImage={false}
                    body="Due to the success of the first GizmoPal product, the company decided to create not just one, but two follow-up devices. The request was to bring two new devices and updated versions of the Android and iOS apps from concept to consumer product in about 7 months. How do you provide more useful functionality without losing the simplicity that made customers like the original product? "
                />

                <ProjectSection
                    title="Leveraging Customer Feedback"
                    variant="colCopyStart"
                    hasImage={false}
                    body="After launching GizmoPal, the core team continued to track customer pain points through the support email, online forums, customer reviews, talking to store reps, and listening to customer support calls. Going into planning for GizmoPal2 and GizmoGadget, I had a lot of information about what customers struggled with, what they loved, and what they felt was missing. I was able to highlight this information during the project kickoff. This helped me keep the project focused on customer needs instead of just adding features to add features. "
                />

                <ProjectSection
                    title="Designing Two Devices"
                    variant="rowCopyStart"
                    hasImage
                    image={diff}
                    imageAlt="differences between devices"
                    body="Unlike the first gizmo, this time around, the hardware was being custom built for the project. I was able to collaborate with the larger core team on the hardware design and specs. Based on the customer's feedback, we needed to make the device smaller, make the band more durable, and offer the bands in more colors. Customers also felt like the original device was for younger kids, so there was opportunity in the 6+ age group. One device was planned to have a touch screen for older kids (GizmoGadget) and the other was planned to have backlit icons only for younger kids (GizmoPal2). This difference meant that the interaction design for each device had to be different. "
                />

                <img src={diff2} alt="more differences between devices" className="project-extra-img project-section-content" />

                <ProjectSection
                    title="Updating the App Design"
                    variant="rowCopyStart"
                    hasImage
                    image={pairing}
                    imageAlt="pairing device"
                    body="Right after launching the first devices, Android and iOS both updated their design guidelines, so part of updating the app was incorporating more of those guidelines and the other part was adding functionality. We were introducing messaging, activity tracking, and alarms/todos on GizmoGadget. We were introducing geofencing and voice recording with fun filters on both devices. We were also increasing the number of contacts kids could communicate with from 2 to 10 on GizmoGadget and 4 on GizmoPal2. It was a challenge to update the app to be able to work with all three devices. The app and device pairing process required a lot more logic. "
                />

                <img src={app} alt="using the app" className="project-extra-img project-section-content" />
                <img src={messaging} alt="messaging using app" className="project-extra-img project-section-content" />

                <ProjectSection
                    title="Making Decisions"
                    variant="rowCopyStart"
                    hasImage
                    image={setup}
                    imageAlt="setup and help screens"
                >
                    <Typography variant="subtitle1">
                        <p>There were many decisions along the way that were very difficult to make, but two really stand out in my mind:</p>
                        <ol>
                            <li>Is asking the customer to select the device type upfront a bad customer experience?</li>
                            <li>Does the security and consistent experience of in-app messaging beat the convenience of native messaging?</li>
                        </ol>
                        <p>Many were adamant that customers not have to select the device type, because it would be a bad customer experience. I wasn't so sure, because to not select the device, we would have to sacrifice being able to provide more concise and more visual instructions during the setup. If the customer didn't select a device type, we would have to give instructions for all three devices until after setup was completed. I would have liked to do some usability testing with showing images of the devices for the customer to choose from similar to what the app does for the help section to see how it impacts the customer experience.</p>
                        <p>Native messaging would be easier to implement and wouldn’'t require a different app, but in-app messaging would provide a secure and consistent experience for the customer no matter what type of phone or messaging app they were using. Either would have been a good solution, so the choice was made based on the product qualities that were most important to the customer.</p>
                    </Typography>
                </ProjectSection>

                <ProjectSection
                    title="The Outcome"
                    variant="colCopyStart"
                    hasImage
                    image={pcmag}
                    imageAlt="pc mag review"
                    body="We successfully launched both products at the same time. We continued making updates based on customer feedback. Due to the success of the release, we were also able to introduce additional accessories and band colors. We also saw increased usage of the application and received a lot of great feedback on the additional functionality built into the mobile application. "
                />

            </div>
        );
    }
}

export default MoreGizmos;