import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import myavatar from '../images/me.jpeg';
import FeaturedWork from '../components/FeaturedWork';
import ContactCard from '../components/ContactCard';
import AboutHero from '../components/AboutHero';
import NavBar from '../components/NavBar';
import healthcare from '../images/healthcare.png';
import mobile from '../images/mobile.png';


class Home extends Component {
    constructor() {
        super();
        this.handleFeatureOne = this.handleFeatureOne.bind(this);
        this.handleFeatureTwo = this.handleFeatureTwo.bind(this);
    }

    handleFeatureOne(event) {
        this.props.history.push('/mobiledesign');
    }

    handleFeatureTwo(event) {
        this.props.history.push('/healthcaredesign');
    }

    render() {
        return (
            <div >
                <NavBar starting={0} logOut={this.props.logOut} openDialog={this.props.openDialog} isLoggedIn={this.props.isLoggedIn} />
                <div className="content">
                <AboutHero 
                    image={myavatar}
                    image_alt="Denise's Picture"
                    title="About Me"
                    body="I'm a passionate user experience design leader who loves working on complicated 
                    and challenging problems. I enjoy working on new concepts, strategizing on product 
                    design, and seeing it all come to life. I'm also a technology lover and gadget enthusiast. 
                    I like learning about new technologies, because I think it helps me in pushing the 
                    boundaries of technology and design to ultimately create better customer experiences."
                    />    
                <Grid container spacing={3} alignItems="stretch">
                    <FeaturedWork 
                        title="Mobile Design" 
                        subtitle="Big solutions for small devices" 
                        body="I've spent most of my career working on mobile apps and devices. The challenges ranged from increasing adoption of smart devices, to designing wearable devices for different market segments, to rethinking activation on the latest network technologies." 
                        handleClick={this.handleFeatureOne} 
                        image={mobile} alt="mobile design"/>
                    <FeaturedWork 
                        title="Healthcare Design" 
                        subtitle="Unbreaking complex systems" 
                        body="For the last few years, I've been diving into the complex web of healthcare. My focus has been on improving the tools healthcare providers and administrators use on a daily basis and empowering patients through more engaging and useful patient experiences." 
                        handleClick={this.handleFeatureTwo} 
                        image={healthcare} 
                        alt="healthcare design"/>
                </Grid>
                <ContactCard />
                </div>
            </div>
        );
    }
}

export default Home;