import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import '../styles/css/MobileProducts.css';
import NavBar from '../components/NavBar';
import MobileDesignAll from './MobileDesignAll';
import SureResponse from './SureResponse';
import OutOfBox from './OutOfBox';
import GizmoPal from './GizmoPal';
import MoreGizmos from './MoreGizmos';
import SmartWatchCalling from './SmartWatchCalling';
import SmartWatchActivation from './SmartWatchActiviation';


class MobileDesign extends Component {
    render() {
        return (
            <div >
                <NavBar starting={1} logOut={this.props.logOut} openDialog={this.props.openDialog} isLoggedIn={this.props.isLoggedIn} />
                <Switch>
                    <Route exact path="/mobiledesign" component={MobileDesignAll} />
                    <Route path="/mobiledesign/sureresponse" component={SureResponse} />
                    <Route path="/mobiledesign/outofbox" component={OutOfBox} />
                    <Route path="/mobiledesign/gizmopal" component={GizmoPal} />
                    <Route path="/mobiledesign/moregizmos" component={MoreGizmos} />
                    <Route path="/mobiledesign/smartwatchcalling" component={SmartWatchCalling} />
                    <Route path="/mobiledesign/smartwatchactivation" component={SmartWatchActivation} />
                </Switch>
            </div>
        );
    }
}

export default MobileDesign;
