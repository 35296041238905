import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/mobiledesign';
import ProjectSection from '../components/ProjectSection';
import '../styles/css/ProjectSection.css';
import callingflow from '../images/calling/callingflow.png';
import numbersharing1 from '../images/calling/numbersharing1.png';
import numbersharing2 from '../images/calling/numbersharing2.png';
import onenumber from '../images/calling/onenumber.png';

class SmartWatchCalling extends Component {
    render() {
        const projectID = 4;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/mobiledesign/moregizmos" next="/mobiledesign/smartwatchactivation" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Challenge"
                    variant="rowCopyStart"
                    hasImage
                    image={onenumber}
                    imageAlt="one number for all devices"
                    body="With the technology of smart watches enabling new capabilities like having Wi-Fi or 4G connectivity, came the idea that users should be able to do more with their watches. Our research indicated that while customers were interested in the flexibility of being able to communicate using multiple devices, the idea of having multiple numbers was not desirable. For instance, if a customer’s watch had a number and they called someone using it, then the person being called wouldn’t know who it was unless the person had the customer’s watch number saved. If watches could magically use an existing phone number, then a customer’s contacts would automatically know it was them calling. The question wasn’t so much about what customers wanted, but about how to deliver it to them. "
                />

                <ProjectSection
                    title="Cross Company Collaboration "
                    variant="colCopyStart"
                    hasImage={false}
                    body="I had the opportunity to collaborate with Google and LG on the first mobile network connected Android Wear watch. The watch was planned to work on the 4G Verizon Wireless network. My focus was primarily on calling and messaging using the watch even when the customer was away from their smartphone. It was great to be working on the next big thing for wearable devices with two industry leading partners. I was able to learn a lot about Android Wear and smart watch technology during the process.  "
                />

                <ProjectSection
                    title="Early Ideas"
                    variant="colCopyStart"
                    hasImage={false}
                    body="The first idea, was only to let the watch handle calling and messaging when it was connected to the phone using bluetooth. This meant always needing to have both devices close together, but it did allow pulling calls from one device to another using bluetooth. Another earlier idea was to add mobile service to the watch and then use call forwarding, so customers could receive calls when away from their phone. This would help support the use case for going out for a run without a phone and not worrying about missing calls. However, it would not help with messaging or with making a call from a customer’s phone number, because the watch would still have a different number for outgoing calls and messaging. "
                />

                <ProjectSection
                    title="Making It Work"
                    variant="colCopyStart"
                    hasImage
                    image={callingflow}
                    imageAlt="calling setup flow"
                    body="The solution I started working on, was how to leverage the same technology we used on tablets for calling and messaging over Wi-Fi or a mobile network. This would mean the watch would no longer have a phone number that could be called directly, and would instead just leverage the phone number of a phone that it was paired with. The experience would be tricky to design, because it would require a setup process that would have to happen across the phone and watch. It would also need to happen post pairing the phone using bluetooth, so there could be confusion over why the customer had to pair again through another process. This is where working with LG and Google to make the secondary setup screens show up right after the Android Wear setup was important. We were also able to get permission to do a special notification if the customer skipped the calling setup initially. We had a limited time frame to pull a combined solution together, but what we came out with, was a strong framework for other watches launching after the first device to follow. "
                />

                <ProjectSection
                    title="Driving Towards a Better Technical Approach"
                    variant="colCopyStart"
                    hasImage
                    image={numbersharing1}
                    imageAlt="number sharing new device"
                    body="The solution I initially implemented worked, but it required a lot of the customer. It required a specific messaging app be used on the phone, there was a multi-step setup process to complete, and it wasn’t scalable to other operating systems or device types. It started to address a customer pain point, but it was only the beginning. While working on the near term solution, I was also working on a strategy for an easier to use and more scalable framework. It required more work to be done by the network and our overall activation technologies, so I worked very closely with my engineering counterparts on the strategy. The plan involved introducing a new concept called Number Sharing. We would allow a customer to share one number on multiple devices as long as all of the sharing devices were activated on the same mobile plan. We could let customers easily manage which devices were sharing which numbers using their online account, or when a new a device was purchased. This meant, beyond activating a device, there would be no future setup or need to use any special app. This approach would allow us to extend the ability to receive the same calls and messages on multiple devices, to any type of device. It would enable customers to get more out of their connected devices, because they would always have the convenience of keeping their phone number consistent across devices. I moved on as the strategy was starting to become reality, but I learned a lot about leveraging a strong user experience vision to drive a technical approach. "
                />

                <img src={numbersharing2} alt="sharing and unsharing numbers" className="project-extra-img project-section-content" />

            </div>
        );
    }
}

export default SmartWatchCalling;