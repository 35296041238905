const firebaseConfig = {
    apiKey: "AIzaSyAV1BbOpAZxmI4T-5iWIX2JY0c_iyFbDS4",
    authDomain: "dlynshue-portfolio.firebaseapp.com",
    databaseURL: "https://dlynshue-portfolio.firebaseio.com",
    projectId: "dlynshue-portfolio",
    storageBucket: "dlynshue-portfolio.appspot.com",
    messagingSenderId: "585842436621"
};
export default firebaseConfig;

