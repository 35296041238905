import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/mobiledesign';
import ProjectSection from '../components/ProjectSection';
import poa from '../images/oobe/oobe_poa.png';
import all from '../images/oobe/oobe_all.png';
import after from '../images/oobe/oobe_after.png';
import after2 from '../images/oobe/oobe_after2.png';
import before from '../images/oobe/oobe_before.png';
import before2 from '../images/oobe/oobe_before2.png';
import teams from '../images/oobe/oobe_teams.png';
import '../styles/css/ProjectSection.css';

class OutOfBox extends Component {
    render() {
        const projectID = 1;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/mobiledesign/sureresponse" next="/mobiledesign/gizmopal" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Challenge"
                    variant="rowCopyStart"
                    hasImage={false}
                    body="First impressions are always important, so providing a great experience the first time a customer interacts with a new product is essential. Customers are often excited to get their new product, so they really want to start playing with it right away, but there are a few steps they need to do first. How do you simplify what customers need to do, decrease the number of customers that require assistance and allow customers to start playing with their new product faster?"
                />

                <ProjectSection
                    title="Identifying and Learning about the Problems"
                    variant="rowCopyStart"
                    hasImage
                    image={all}
                    imageAlt="out of box materials"
                    body="Despite including materials with phones about how to activate them and how to set them up, there was still a high volume of calls to our customer service team from customers setting up new phones. I worked with a core team focused on this issue to start to understand why customers were calling. I also started listening to customer calls made within 2 days of receiving a new phone. I started tracking statistics on types of phones customers were calling in more often about. I collected all of the materials we were sending customers. I visited distribution centers to understand how the packages being sent to customers were put together. I wanted to learn as much as I could."
                />

                <ProjectSection
                    title="Defining the Problems"
                    variant="rowCopyEnd"
                    hasImage
                    image={teams}
                    imageAlt="initial concept"
                    body="After completing the research, I could start to see patterns, like customers with 4G smartphones were calling more often than anyone else. From looking at the materials, I could see that we were using setup guides to convey a lot of information that really had nothing to do with setup. The biggest problem was that many different teams were working in silos on different parts of the experience and they weren't working together. There was no one looking at the big picture."
                />

                <ProjectSection
                    title="Tackling the Problems"
                    variant="colCopyStart"
                    hasImage
                    image={before}
                    imageAlt="in box set up materials"
                    body="To achieve any level of change I felt like it was important to start with getting to know the teams working on the different pieces of the experience and build a rapport with those teams. I tracked down all of the owners and introduced myself and what I was tasked to do and why. The teams were very receptive and eager to make improvements. I found that the teams didn't know each other, so I could add value by bringing them together. I also found that the teams producing the materials didn't always have help with writing copy and producing images. In many ways they were stuck with what was provided by a vendor. Again, I was able to build credibility for my team, by bringing in experts on copy and visuals. This was a huge step forward, because once teams realized I wasn't just there to scrutinize their work, they were really excited to work with me.

                    My next step was figuring out why things were the way they were. Why did you have to call from another phone to activate? Why did we need all of these disclaimers? Why are we using so much valuable real estate to promote apps to customers, before we tell them how to use touch gestures? Why do multiple pieces of paper say 'Start Here' when I can only start in one place? What I learned was that everyone was just doing what another team asked them to do. There was no one asking two very important questions:
                    
                    Does the customer need this information to successfully start using their phone?
                    Does this match the customer's expectations?
                    I started asking those questions. There was a lot of information that wasn't necessary. There were also a few steps that were unexpected or worked in an unexpected way."
                />
                <img src={before2} alt="phone set up guide" className="project-extra-img project-section-content"/>

                <ProjectSection
                    title="Designing, Testing and Iterating"
                    variant="colCopyStart"
                    hasImage
                    image={after2}
                    imageAlt="initial concept"
                    body="To address the instructional materials, I started slashing blocks of copy, making images bigger, removing pages of paper. I worked with copywriters on my team to make new versions as prototypes and then the UX researchers on my team put together a multi-round usability test. I worked closely with the research team because they started running usability testing at the same time I started working on improving the materials. We ended up running 3 rounds of testing, totally in over 40 participants. After a few participants we would meet and make a few tweaks on the design to address the issues and then run several more usability tests. We worked with a consultant to help with instructional images and went through about 10 different design iterations. We went from a 0% success rate in round one, to a 30% success rate in round two to 70% success rate in round three. Success was measured as a participant who was able to activate the phone and make a call without asking for assistance or getting to a point where they would decide to call customer support."
                />
                <ProjectSection
                    title="The Short Term Outcome"
                    variant="rowCopyEnd"
                    hasImage
                    image={after}
                    imageAlt="initial concept"
                    body="In the short term we were able to rename items, remove items, and create materials that worked together to guide customers through the process. We went from having a 3 to 4 page guide on how to activate a device that was phone specific to a 1 page guide that could be used for all smartphones, which resulted in streamlining the information to customers and increasing efficiency for the distribution centers. We also went from having an about your device guide with many panels of small copy and little images to having a guide with clearly labeled steps, improved text, larger font size and bigger images. We also developed modules of reusable copy that could be used to build guides for future devices easily.

                    We also learned a lot about customer expectations and behaviors when getting a new phone. Customers forget about the old phone when the new one arrives. Customers want it to just work when they turn it on. Some customers like to read and others just want to learn by trial and error. We were able to use this information to make plans for longer term strategic projects. One of those projects involved working with the Network team to design a process for phones to automatically activate when you turn them on. It's amazing what can be accomplished when you break down the silos and work together towards a common goal."
                />
                <ProjectSection
                    title="The Longterm Outcome"
                    variant="colCopyStart"
                    hasImage
                    image={poa}
                    imageAlt="long term concept"
                    body="In the longterm, I collaborated with the IT and Network teams to design a new activation flow. With improved instructional material, success rate improved to 70%, but there was still something missing. The usability testing uncovered a larger issue: We were asking customers to do something that they didn't expect to do. We taught customers to tap an 'Activate your phone'
                     button on older phones, but on newer phones, we were asking them to activate by making a call from a different phone. Customers didn't expect to need to use a different phone to activate their new phone. They expected to just do something on the new phone to activate it. Based on this issue, the Network team supporting the system the customer had to call, came up with a technical solution to allow for activating using the new phone only. I worked closely with them to design the concept and get buy in from others in the company to move forward. I created clickable flows others could play with because the logic was complicated, but very important to understand. A few years later, the solution is starting to go live on smartphones."
                />
            </div>
        );
    }
}

export default OutOfBox;