import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/healthcaredesign';
import ProjectSection from '../components/ProjectSection';
import alpha from '../images/quality/alpha.png';
import collections from '../images/quality/collections.png';
import milestones from '../images/quality/milestones.png';
import research from '../images/quality/research.png';


class Quality extends Component {
    render() {
        const projectID = 4;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/healthcaredesign/patientpayments" next="/healthcaredesign/quality" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Challenge"
                    variant="colCopyStart"
                    hasImage={false}
                    body="To keep up with the demand from clients and improve our ability to build and calculate results for quality measures, we were transitioning to a new quality measure engine. The engine would calculate results faster, be more accurate, and certified for the most common government programs. However, the new engine wouldn’t play nicely with existing reports used by our clients. The data the engine was providing wouldn’t be easily consumable by the existing front-end experiences. This provided an opportunity to take a look at the existing reports, research what was working and what needed to be improved, and to create a strategy for the future of quality management reporting. "
                />

                <ProjectSection
                    title="Recognizing the Need to Pivot"
                    variant="colCopyStart"
                    hasImage={false}
                    body="The project had started with a focus on building a new experience for a subset of our users, but we had multiple quality reporting experiences in our Population Health tool and our EHR. There was an even larger opportunity to create an experience that worked across all of our products seamlessly. It was time to pivot from building an experience for only Population Health users, to building an experience that could also support our EHR users. "
                />

                <ProjectSection
                    title="Pulling the Right Team Together"
                    variant="colCopyStart"
                    hasImage={false}
                    body="I had done much of the early design work on my own, but given the scope of the project was changing, it was time to pull in additional resources. I worked with my director and the chief of experience design to open up two positions and build out a small team. I was looking for team players, who were passionate about design, and not afraid to jump into a complex domain. The one thing I had learned so far about quality work, was that I always had more to learn. Luckily I was able to find two amazing designers to join the team, and we partnered with a knowledgeable product strategist. Combined with a strong product management team, we were ready to take on the project. "
                />

                <ProjectSection
                    title="Foundational Research"
                    variant="colCopyStart"
                    hasImage
                    image={research}
                    imageAlt="interview notes"
                    body="With the additional scope came additional user roles, so my team and I worked closely with the product team to organize and conduct a series of client interviews. We also volunteered to run a workshop with clients visiting the office, because we wanted to collect as much information as possible. We sent out multiple concept tests and set up design feedback calls with additional clients. What we learned during our initial research was invaluable to the project and it helped us plan and prioritize work for the next set of sprints. "
                />

                <ProjectSection
                    title="Working as a Team"
                    variant="colCopyStart"
                    hasImage
                    image={collections}
                    imageAlt="abstract collections"
                    body="To complete all of the research and churn out concepts to test, we had to work together. The work was all very intertwined, so working as individuals wasn’t really an option. We started using a tool called Abstract, so that multiple designers could work on the same files and leverage merging and version controls, very similar to developers using Github. We also shared work sometimes multiple times a day with product owners and developers to get feedback. The designers on my team created collections of screens and a prototype that showed the latest designs and we provided a way for anyone on the project team to access them whenever. It helped us move faster and stay aligned. "
                />

                <ProjectSection
                    title="Setting Attainable Goals "
                    variant="colCopyStart"
                    hasImage
                    image={milestones}
                    imageAlt="development milestones"
                    body="When starting out a large project, it can be easy to get lost in the scope and forget to celebrate small victories along the way. Teams can also slow down when the target seems too far away. To keep the teams motivated, I worked with one of the product owners and one of the engineering managers to break up the work into meaningful milestones. A milestone was typically one vertical slice that the teams could demo end to end. This allowed for everyone to feel a sense of accomplishment as each milestone was completed. I think it also accelerated the pace of development, because we were always working towards a goal that was within reach in a sprint or two. "
                />

                <ProjectSection
                    title="The Outcome"
                    variant="colCopyStart"
                    hasImage
                    image={alpha}
                    imageAlt="alpha preview"
                    body="We were able to build out a quality management dashboard that both our clients and our customer facing co-workers were excited to see go out to clients as a minimum loveable product. From a design perspective, there is a lot more work to do to enhance this dashboard. What we’ve built so far is a scalable base that we can learn from and continue to improve on. It was all made possible through teamwork and having the passion to push through barriers towards a building a better solution."
                />
            </div>
        );
    }
}

export default Quality;