import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/healthcaredesign';
import ProjectSection from '../components/ProjectSection';
import dashboard from '../images/provider/pcp_dashboard.png';
import details from '../images/provider/pcp_details.png';
import async from '../images/provider/asynchronous_loading.png';
import '../styles/css/ProjectSection.css';

class ProviderPerformance extends Component {
    render() {
        const projectID = 1;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/healthcaredesign/patientsummary" next="/healthcaredesign/patientexperience" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Challenge"
                    variant="rowCopyStart"
                    hasImage={false}
                    body="When trying to improve the health of patients, while driving down unnecessary costs, being able to view how the providers in your organization are performing on key metrics is very important. While all of the information was accessible within the product I was supporting, it wasn’t all in one place. Clients would often have someone at their organization gather the information and then print it out to share with providers and leadership. If we could pull together all of the information in one place, then we could save our clients time and provide a valuable tool. "
                />

                <ProjectSection
                    title="Showing the Right Metrics"
                    variant="rowCopyStart"
                    hasImage={false}
                    body="From talking to clients, the product owner and I learned that providers are typically competitive and one way practices sought to motivated providers was by showing them how they compare to their peers and certain targets. A previous designer had completed the research and designs for an alpha, so I was picking up where she left off and trying to leverage the feedback to create an improved design that we could launch to all clients. The alpha was built quickly and had some data integrity issues, so it was necessary to rebuild. We also received some feedback that the metrics and the filters provided for each were not ideal. To help us work through improving the data and providing more meaningful metrics and filters, we pulled in some internal subject matter experts. They were able to help us revise the information shown and prioritize the order in which to rebuild."
                />

                <ProjectSection
                    title="Designing Components"
                    variant="colCopyStart"
                    hasImage
                    image={dashboard}
                    imageAlt="reusable metric card component"
                    body="Much of the design was actually just a few reusable components with different content in each. There was a metric summary card and a metric detail page. The metric summary card had a graph and one to three insights. The metric detail page had a larger version of the information shown on the summary card and included a table that had different view by options. Previously each card and page was a different design, but that would mean that every knew metric added to the tool would require additional design. I though if everything was designed as reusable components, then each metric added could just reuse the same design and most of the same code. This meant that after the first metric, each additional metric would be faster to build. The focus would rightfully be on the content within the components instead of the format of the component."
                />

                <ProjectSection
                    title="Enhancing the Experience"
                    variant="colCopyStart"
                    hasImage
                    image={details}
                    imageAlt="metric details"
                    body="Beyond building reusable components, I also looked for ways to improve the usability and performance of the tool. I noticed that every time I clicked into a metric detail screen, when I went back to the summary screen, it had to reload even though nothing should have changed. It appeared as if the data was never cached and just always reloading which would result in users constantly waiting for screens to load. I also noticed that when I clicked into a metric detail screen, I had to wait for the graph and insights that I was just looking at on the summary screen to reload. In addition to a lack of caching data and only loading what is new, there was also no progressive loading, so the page either showed all of the content or a loader and no content. I worked with the developers to highlight the importance of making updates to performance and also making changes that would give users the perception of better performance. Even with updated data queries, the amount of data loading would always take a few seconds. Caching data, loading sections of the screen as available, and showing skeleton screens until real data was loaded were all ways to improve the perception of the performance for a data heavy tool."
                />

                <img src={async} alt="async loading" className="project-extra-img project-section-content" />
            </div>
        );
    }
}

export default ProviderPerformance;