import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import '../styles/css/ProjectSection.css';


const RowCopyStart = (props) => {
    return (
        <div className="project-section-content">
            <Typography variant="h5" gutterBottom>
                {props.title}
            </Typography>

            {props.hasImage
                ? <Grid
                    container
                    direction="row"
                    spacing={4}
                    alignItems="flex-start"
                >
                    <Grid item xs={12} sm={7}>
                        <Typography variant="subtitle1" >
                            {props.body}
                        </Typography>
                        {props.children}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <img alt={props.imageAlt} src={props.image} className="project-section-row-img" />
                    </Grid>
                </Grid>
                : <Grid
                    container
                    direction="row"
                    spacing={2}
                >       <Grid item xs={12} >
                        <Typography variant="subtitle1" >
                            {props.body}
                        </Typography>
                        {props.children}
                    </Grid>
                </Grid>
            }


        </div>
    );
};

const RowCopyEnd = (props) => {
    return (
        <div className="project-section-content">
            <Typography variant="h5" gutterBottom >
                {props.title}
            </Typography>
            {props.hasImage
                ? <Grid
                    container
                    direction="row-reverse"
                    spacing={4}
                    alignItems="flex-start"
                >
                    <Grid item xs={12} sm={7}>
                        <Typography variant="subtitle1" >
                            {props.body}
                        </Typography>
                        {props.children}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <img alt={props.imageAlt} src={props.image} className="project-section-row-img" />
                    </Grid>
                </Grid>
                : <Grid
                    container
                    direction="row"
                    spacing={2}
                >       <Grid item xs={12} >
                        <Typography variant="subtitle1">
                            {props.body}
                        </Typography>
                        {props.children}
                    </Grid>
                </Grid>
            }
        </div>
    );
};

const ColCopyStart = (props) => {
    return (
        <div className="project-section-content">
            <Typography variant="h5" gutterBottom>
                {props.title}
            </Typography>

            <div className="project-section-col">
                <Typography variant="subtitle1" className="project-section-top">
                    {props.body}
                </Typography>
                {props.children}
                <img alt={props.imageAlt} src={props.image} className="project-section-bottom project-section-img" />
            </div>
        </div>
    );
};

const ColCopyEnd = (props) => {
    return (
        <div className="project-section-content">
            <Typography variant="h5" gutterBottom>
                {props.title}
            </Typography>
            <div className="project-section-col">
                <img alt={props.imageAlt} src={props.image} className="project-section-top project-section-img" />
                <Typography variant="subtitle1" className="project-section-bottom">
                    {props.body}
                </Typography>
                {props.children}
            </div>
        </div>
    );
};


const ProjectSection = (props) => {
    if (props.variant === "rowCopyStart") {
        return (
            <RowCopyStart {...props} />

        )
    }
    if (props.variant === "rowCopyEnd") {
        return (
            <RowCopyEnd {...props} />
        )
    }
    if (props.variant === "colCopyStart") {
        return (
            <ColCopyStart {...props} />
        )
    }

    if (props.variant === "colCopyEnd") {
        return (
            <ColCopyEnd {...props} />
        )
    }
    else {
        return (
            <RowCopyStart {...props} />
        )
    }

}

export default ProjectSection;


