import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/healthcaredesign';
import ProjectSection from '../components/ProjectSection';
import demo from '../images/patientexperience/patient-ux-demo.png';
import principles from '../images/patientexperience/patient-ux-design-principles.png';
import uxstory from '../images/patientexperience/patient-ux-story.png';
import agile from '../images/patientexperience/patient-ux-agile.png';
import waterfall from '../images/patientexperience/patient-ux-waterfall.png';
import strategy from '../images/patientexperience/patient-ux-strategy.png';
import '../styles/css/ProjectSection.css';

class PatientExperience extends Component {
    render() {
        const projectID = 2;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/healthcaredesign/providerperformance" next="/healthcaredesign/patientpayments" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="The Challenge"
                    variant="colCopyStart"
                    hasImage={false}
                    body="Due to the work I had started on patient payments, I was tasked with setting up a zone which would be responsible for all of our patient or health care consumer facing products. The main product being a legacy patient portal that hadn’t been updated beyond a few minor tweaks in the last few years. The zone was going to be staffed out of our Austin, Texas and Bangalore, India offices, so my role was to help build the teams, outline processes, and create a user experience strategy that would help to set the zone up for success. It was an interesting challenge to be focused on not just getting patient payment work to market, but also building up a sustainable framework and strategy that would need to be able to thrive without me being present."
                />

                <ProjectSection
                    title="Building Credibility"
                    variant="colCopyStart"
                    hasImage
                    image={demo}
                    imageAlt="design review demo"
                    body="To get started, I needed to learn more about the existing patient portal and to build up strong relationships with my product and engineering partners. My partners from product and engineering had only worked with user experience as a step in their process or a group to consult, but not as a fully invested partner. From discussions with others in the zone, I learned that sometimes user experience was viewed as a black box, where product requirements or issues go in and then a few weeks later, beautiful designs pop out. I also learned that engineering had concerns of being provided designs that were not feasible or needed to be scaled back. To help get past some of those concerns, I created a recurring design review meeting. I thought it would be a simple way to keep everyone up to date with what’s going on in design, maintain alignment with product and engineering, provide transparency into the experience design process, and get early feedback on research plans and design concepts. "
                />

                <ProjectSection
                    title="Design Principles"
                    variant="colCopyStart"
                    hasImage
                    image={principles}
                    imageAlt="design principles"
                    body="Many of the people working in the zone were coming from working on other products that focused on a clinician as the user. Patients were a very different user, so I created a few guiding design principles to help the team."
                >
                    <Typography variant="subtitle1">
                        <ul>
                            <li>Patients don’t speak doctor: We want to use a warm and conversational tone, plain language, and establish a human connection.</li>
                            <li>Build competence and trust: We want our clients to feel confident in our ability to help them improve their patient experience, our clients want their patients to feel supported outside of the office, and patients want to feel empowered to engage in their healthcare.</li>
                            <li>Mobile first doesn’t mean mobile only: We want to offer the best experience possible on whatever device the user wants to use throughout their journey.</li>
                            <li>Test early, test often, fail cheap: We want to be testing our designs and concepts early, so we can understand what works and what doesn’t work.</li>
                            <li>Guided, but not rigid: We want to focus on having an experience that uses content to engage and guide the user, while not putting obstacles in between the user and their goal. </li>
                            <li>We are not the frontline: When a patient has a question or issue, they will call our clients, so our patient experiences need to align with the experiences we provide our clients.</li>
                        </ul>
                    </Typography>

                </ProjectSection>

                <ProjectSection
                    title="Creating a UX Story "
                    variant="colCopyStart"
                    hasImage
                    image={uxstory}
                    imageAlt="UX story"
                    body="The new zone was suffering from a lack of focus and priority. We had a limited number of teams and a lot of ideas in flight. I thought having a clear story of what user experience was working on would help focus the zone. We were reviewing what was there, making a plan for how to break it down and start redesigning, while building up our foundational knowledge about our users, and finally starting to build our first microservice. Creating the story helped me to focus in on the work that aligned with the story and make the case to de-prioritize work that didn’t align."
                />

                <ProjectSection
                    title="Aligning on the Process"
                    variant="colCopyStart"
                    hasImage
                    image={waterfall}
                    imageAlt="the wrong process"
                    body="I found that the UX story aligned the designers on the team, but there was still a disconnect with the product manager. The product manager was still looking for a completed set of designs that the developers could then break up and start building. It wasn’t very agile. He was thinking about jumping from the current patient portal to a new patient portal, but he didn’t seem to be thinking about delivering incremental value along the way. I created a few visuals to help guide my conversations with product and engineering. Scrum team roles and process seemed unclear, so having these discussions helped provide clarity to the teams and enabled the zone to get to a shared process."
                />
                <img src={agile} alt="the agile process" className="project-extra-img project-section-content" />

                <ProjectSection
                    title="Setting Others up for Success "
                    variant="colCopyStart"
                    hasImage
                    image={strategy}
                    imageAlt="inside out strategy"
                    body="Prior to leaving the zone, I was able to set up the overall processes for the zone, design principles, a high level vision, and a strategy for how to take steps towards the vision. We made great strides towards a plan of starting to replace the most used sections of the portal with updated microservices until we could slowly replace the rest of the portal. This would move the zone away from the unscalable monolith and give it a chance to provide improved experiences one step at a time. It was difficult to leave a zone behind, but I felt content knowing I was leaving the zone with good direction and momentum. "
                />

            </div>
        );
    }
}

export default PatientExperience;