import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import NavBar from '../components/NavBar';
import { Switch, Route, Redirect } from 'react-router-dom'
import HealthcareAll from './HeathcareAll';
import PatientSummary from './PatientSummary';
import ProviderPerformance from './ProviderPerformance';
import PatientExperience from './PatientExperience';
import PatientPayments from './PatientPayments';
import Quality from './Quality';

function PrivateRoute({ component: Component, authenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authenticated === true
                ? <Component {...rest} {...props} />
                : <Redirect to={{ pathname: '/healthcaredesign', state: { from: props.location } }} />}
        />
    )
}

class HealthcareDesign extends Component {
    render() {

        return (
            <div >
                <NavBar starting={2} logOut={this.props.logOut} openDialog={this.props.openDialog} isLoggedIn={this.props.isLoggedIn} />
                <Switch>
                    <Route exact path="/healthcaredesign" render={(props) => <HealthcareAll {...props} showLogin={this.props.showLogin} isLoggedIn={this.props.isLoggedIn} />} />
                    <PrivateRoute authenticated={this.props.isLoggedIn} path="/healthcaredesign/patientsummary" component={PatientSummary} />
                    <PrivateRoute authenticated={this.props.isLoggedIn} path="/healthcaredesign/providerperformance" component={ProviderPerformance} />
                    <PrivateRoute authenticated={this.props.isLoggedIn} path="/healthcaredesign/patientexperience" component={PatientExperience} />
                    <PrivateRoute authenticated={this.props.isLoggedIn} path="/healthcaredesign/patientpayments" component={PatientPayments} />
                    <PrivateRoute authenticated={this.props.isLoggedIn} path="/healthcaredesign/quality" component={Quality} />
                </Switch>
            </div>
        );
    }
}

export default HealthcareDesign;