import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import '../styles/css/FeaturedWork.css';
import { CardActionArea } from '@material-ui/core';

const FeaturedWork = (props) => {
    return (

        <Grid item xs={12} md={6}>
            <Card className="card-feature" onClick={props.handleClick}>
                <CardActionArea >
                    <div className="card-details">
                        <CardContent >
                            <Typography component="h2" variant="subtitle2" color="textSecondary" gutterBottom>
                                {props.title}
                            </Typography>
                            <Typography variant="h5" color="textPrimary" gutterBottom>
                                {props.subtitle}
                            </Typography>
                            <Typography variant="subtitle1" paragraph>
                                {props.body}
                            </Typography>
                        </CardContent>
                    </div>
                </CardActionArea>
                <Hidden xsDown>
                    <CardMedia className="card-media"
                        image={props.image}
                        title={props.alt}
                    />
                </Hidden>

            </Card>
        </Grid >

    );
}

export default FeaturedWork;