import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Tabs, Tab, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import '../styles/css/NavBar.css';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

function LinkTab(props) {
    return <Tab component={Link} to={props.section} {...props} />;
}

function HideOnScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger();
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
    }
  
  HideOnScroll.propTypes = {
    children: PropTypes.node.isRequired,
  };

class NavBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.starting,
        };

        this.handleChange = (event, value) => {
            this.setState({ value });
        };

    }

    render() {

        const { value } = this.state;

        return (
            <div>
                <HideOnScroll>
                <AppBar >
                    <Toolbar className="nav-toolbar">
                        <div className="nav-title">
                            <Typography variant="h5" color="inherit">
                                Denise Lyn-Shue
                            </Typography>
                            <Typography variant="body1" color="inherit">
                                UX Design & Strategy
                            </Typography>
                        </div>
                        <div className="nav-tabs">
                            <Tabs variant="fullWidth" value={value} onChange={this.handleChange}>
                                <LinkTab label="About" section="/" />
                                <LinkTab label="Mobile Design" section="/mobiledesign" />
                                <LinkTab label="Healthcare Design" section="/healthcaredesign" />
                            </Tabs>
                            {this.props.isLoggedIn
                                ? <IconButton aria-label="LinkedIn" onClick={this.props.logOut}>
                                    <LockOpenIcon color="inherit" />
                                </IconButton>
                                : <IconButton aria-label="LinkedIn" onClick={this.props.openDialog}>
                                    <LockIcon color="inherit" />
                                </IconButton>
                            }
                        </div>
                    </Toolbar>
                </AppBar>
                </HideOnScroll>
            </div>
        );
    }

}

NavBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default NavBar;