import React from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import '../styles/css/AboutHero.css';
import { withStyles } from '@material-ui/core/styles'; //needed to override styles

const styles = {
    bigAvatar: {
        width: 140,
        height: 140,
        marginBottom: 16,
    },
    text: {
        marginBottom: 32,
    }
};

const AboutHero = (props) => {
    const { classes } = props;
    return (
        <div className="hero-about">
            <Avatar className={classes.bigAvatar} alt={props.image_alt} src={props.image} />
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                {props.title}
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" className={classes.text} paragraph>
                {props.body}
            </Typography>
        </div>

    );
}

export default withStyles(styles)(AboutHero);