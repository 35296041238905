import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ProjectCard from '../components/ProjectCard';
import '../styles/css/MobileProducts.css';
import data from '../content/mobiledesign';


class MobileDesignAll extends Component {
    render() {
        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;

        return (
            <div >
                <div className="content">
                    <div className="hero-mobile">
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            Mobile Design
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            While designing mobile apps and devices, I've had the opportunity to work on some
                            unique projects. I've been able to design products for seniors, children, tech enthusiast, and many others.
                            The projects below highlight some of the challenges, my approach, and what I learned along the way.
                    </Typography>
                    </div>

                    <Grid container spacing={4} >
                        {viewProjects.map(project => (
                            <ProjectCard key={project.id} image={require(`../images/${project.image}.png`)} title={project.title} summary={project.summary} linkToProject={project.link} />
                        ))}
                    </Grid>

                </div>
            </div>
        );
    }
}

export default MobileDesignAll;