import React, { Component } from 'react';
import './styles/css/App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/deepPurple';
import red from '@material-ui/core/colors/red';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';


import withFirebaseAuth from 'react-with-firebase-auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './firebaseConfig';


import MobileDesign from './routes/MobileDesign';
import Home from './routes/Home';
import HealthcareDesign from './routes/HealthcareDesign';
import LoginDialog from './components/LoginDialog';
import SuccessSnack from './components/SuccessSnack';
import ScrollToTop from './components/ScrollToTop';


const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: deepPurple,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();

class App extends Component {

  state = {
    user: null,
    isLoggedIn: false,
    openDialog: false,
    email: "guest@user.com",
    password: "",
    errorMessage: "",
    hasError: false,
    loggedIn: false,
    loggedOut: false,
    loggedInMesssage: "Portfolio Unlocked",
    loggedOutMesssage: "Portfolio Locked"
  }

  handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      loggedIn: false,
      loggedOut: false
    });
  };

  handleOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({
      openDialog: false,
      hasError: false,
      password: ""
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      hasError: false
    })
  };

  handleSubmit = () => {
    firebaseAppAuth.signInWithEmailAndPassword(this.state.email, this.state.password).then((result) => {
      this.setState({
        openDialog: false,
        isLoggedIn: true,
        password: "",
        user: result.user,
        hasError: false,
        loggedIn: true
      })
    }).catch((error) => {
      //var errorCode = error.code;
      //var errorMessage = error.message; to use the standard error messages 
      this.setState({
        errorMessage: "You have entered an valid password.",
        hasError: true,
      })
    })
  };

  keyPressed = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  }

  handleLogOut = () => {
    firebaseAppAuth.signOut();
    this.setState({
      isLoggedIn: false,
      loggedOut: true
    });
  };

  render() {

    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <SuccessSnack
            showSnack={this.state.loggedIn}
            icon={<LockOpenIcon color="inherit" style={{ fontSize: 18, marginRight: 8 }} />}
            snackMessage={this.state.loggedInMesssage}
            handleClose={this.handleSnackClose} />
          <SuccessSnack
            showSnack={this.state.loggedOut}
            icon={<LockIcon color="inherit" style={{ fontSize: 18, marginRight: 8 }} />}
            snackMessage={this.state.loggedOutMesssage}
            handleClose={this.handleSnackClose} />
          <LoginDialog
            open={this.state.openDialog}
            close={this.handleClose}
            submit={this.handleSubmit}
            password={this.state.password}
            emailChange={this.handleEmailChange}
            passwordChange={this.handlePasswordChange}
            error={this.state.hasError}
            errorMessage={this.state.errorMessage}
            keyPress={this.keyPressed}
           />
          
          <Router>
          <ScrollToTop>  
            <Route exact path="/" render={(props) => <Home {...props} openDialog={this.handleOpen} isLoggedIn={this.state.isLoggedIn} logOut={this.handleLogOut} />} />
            <Route path="/mobiledesign" render={(props) => <MobileDesign {...props} openDialog={this.handleOpen} isLoggedIn={this.state.isLoggedIn} logOut={this.handleLogOut} />} />
            <Route path="/healthcaredesign" render={(props) => <HealthcareDesign {...props} openDialog={this.handleOpen} isLoggedIn={this.state.isLoggedIn} logOut={this.handleLogOut} showLogin={this.handleOpen} />} />
            </ScrollToTop>  
          </Router>
          


        </MuiThemeProvider>
      </div>
    );
  }
}



export default withFirebaseAuth({
  firebaseAppAuth,
})(App);
