import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ProjectCard from '../components/ProjectCard';
import PrivateProjectCard from '../components/PrivateProjectCard';
import '../styles/css/MobileProducts.css';
import data from '../content/healthcaredesign';

class HealthcareAll extends Component {
    render() {
        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;

        return (
            <div >
                <div className="content">
                    <div className="hero-mobile">
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            Healthcare Design
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            Making the move from consumer mobile products
                            to enterprise and consumer healthcare applications meant leaving my comfort zone.
                            Designing for healthcare is extremely rewarding. You are constantly being
                            challenged to solve very relatable problems within super complex systems.
                            You truly have an opportunity to improve the healthcare system.
                        </Typography>
                    </div>
                    {this.props.isLoggedIn
                        ? <Grid container spacing={4} >
                            {viewProjects.map(project => (
                                <ProjectCard key={project.id}
                                    image={require(`../images/${project.image}.png`)}
                                    title={project.title}
                                    summary={project.summary}
                                    linkToProject={project.link}
                                />
                            ))}
                        </Grid>

                        : <Grid container spacing={4} >
                            {viewProjects.map(project => (
                                <PrivateProjectCard key={project.id}
                                    image={require(`../images/${project.image}.png`)}
                                    title={project.title}
                                    summary={project.summary}
                                    showLogin={this.props.showLogin}
                                />
                            ))}
                        </Grid>
                    }

                </div>
            </div>
        );
    }
}

export default HealthcareAll;