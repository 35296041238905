import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import deepPurple from '@material-ui/core/colors/deepPurple';
import { withStyles } from '@material-ui/core/styles'; //needed to override styles
import Slide from '@material-ui/core/Slide';



const styles = {
    root: {
        background: deepPurple[700]
    }
};

function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

class SuccessSnack extends Component {

    render() {
        const { classes } = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.props.showSnack}
                autoHideDuration={3000}
                onClose={this.props.handleClose}
                TransitionComponent={TransitionLeft}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: classes.root
                    }
                }}
                message={<span id="message-id">
                    {this.props.icon}
                    {this.props.snackMessage}
                </span>}

            />
        );
    }
}

export default withStyles(styles)(SuccessSnack);