import React, { Component } from 'react';
import {
    Dialog, DialogTitle, DialogContent, Typography,
    DialogActions, Button, TextField, DialogContentText,
} from '@material-ui/core';
import '../styles/css/LoginDialog.css'


class LoginDialog extends Component {

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.close}
                    aria-labelledby="login-dialog"
                    maxWidth="xs"
                >
                    <DialogTitle id="login-dialog">
                        Unlock Portfolio
                    </DialogTitle>

                    <DialogContent>

                        <DialogContentText>
                            Enter your password to access the locked content in this portfolio.
                        </DialogContentText>

                        <div className="login-field">
                            <TextField
                                fullWidth
                                autoFocus
                                error={this.props.error}
                                id="password"
                                label="Password"
                                type="password"
                                value={this.props.password}
                                onChange={this.props.passwordChange}
                                onKeyPress={this.props.keyPress}
                            />
                        </div>
                        {this.props.error
                            ? <div className="login-error" >
                                <Typography variant="body2" >
                                    {this.props.errorMessage}
                                </Typography>
                            </div>
                            : null
                        }

                    </DialogContent>
                    <div className="login-actions">
                        <DialogActions>

                            <Button onClick={this.props.close} color="primary">
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={this.props.submit} color="primary">
                                Enter
                            </Button>

                        </DialogActions>
                    </div>
                </Dialog>
            </div >
        );
    }
}

export default LoginDialog;