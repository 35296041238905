import React, { Component } from 'react';
import '../styles/css/MobileProducts.css';
import Project from '../components/Project';
import ProjectStepper from '../components/ProjectStepper';
import data from '../content/healthcaredesign';
import ProjectSection from '../components/ProjectSection';
import error from '../images/patientpay/quickpay-error.png';
import current from '../images/patientpay/quickpay-current.png';
import qrcode from '../images/patientpay/QR-code.gif';
import paymentplan from '../images/patientpay/quickpay-paymentplan.png';
import beta from '../images/patientpay/quickpay-beta.png';
import code from '../images/patientpay/quickpay-code.png';


class PatientPayments extends Component {
    render() {
        const projectID = 3;

        //Get Content by ID 
        const projects = data[0];
        const viewProjects = projects.projects;
        const project = viewProjects[projectID];

        return (
            <div className="detail-content">
                <ProjectStepper steps={viewProjects.length} starting={projectID} previous="/healthcaredesign/patientexperience" next="/healthcaredesign/quality" />
                <Project title={project.title} summary={project.summary} />

                <ProjectSection
                    title="Tackling a Problem"
                    variant="rowCopyStart"
                    hasImage
                    image={error}
                    imageAlt="error message"
                    body="It all started with a simple request. A product owner that didn’t have a scrum designer reached out to ask for an error message. At the time, I was supporting another group of teams, but also ramping up on patient work, so I could provide help until more designers were hired. Helping with an error message seemed like an easy enough task, so I asked what it was it for and how users could resolve the error. The error message needed to inform users that were visiting one of our patient payment websites using a mobile phone, that they couldn’t continue and needed to use a computer. So many questions came to mind once I learned about what the message was for. What year is it? Why were redirecting mobile users to a computer? Why are we building an error message instead of a way for users to pay using a phone? There was clearly a huge problem here and also a huge opportunity. My director and I decided to dig deeper and take on the real problem. "
                />

                <ProjectSection
                    title="Understanding the Challenges"
                    variant="colCopyStart"
                    hasImage
                    image={current}
                    imageAlt="old mobile experience"
                    body="The project was currently being supported by a team that was supporting all of our patient facing applications. They were under resourced and faced with client escalations and a backlog of bugs to be fixed. It was clear that they only had time to throw some copy at the problem, which in this case, was definitely not enough. The first question I had was, what was the current experience? A quick review revealed that users could access the login screen, enter the code on their statement, but after the code was validated the site would just show a spinner forever. The only thing worse than not having a mobile experience, was having a misleading experience, that let a user type in a 15 character code only to lead them into an unmarked dead-end. The next question, was why didn’t we have a mobile experience? The product team felt like the it would be a big lift, because currently they were supporting mobile by building out separate mobile versions instead of using one responsive or adaptive code base. "
                />

                <ProjectSection
                    title="Aligning the Problem with the Business Value"
                    variant="colCopyStart"
                    hasImage={false}
                    body="The problem was clear, in many ways the high level solution was clear, but the path forward was fuzzy. We needed a way to highlight the opportunity and to show what was possible. My director and I spent time doing research around mobile payments for healthcare and other industries to highlight the trends in payments. We found compelling information around bills paid using mobile devices and that patient payments took double the time to be paid when compared to insurance payments. In many cases, large bills ended up going to collections, because patients couldn’t pay a lump sum and didn’t good options for making monthly payments. This meant our clients were waiting longer to get paid and in some cases were not getting paid at all. The research gathered helped to tie the patient problem to our clients problem and to our business."
                />

                <ProjectSection
                    title="Gathering Support"
                    variant="colCopyStart"
                    hasImage={false}
                    body="We also looked for partners to help garner support and found that the product strategy lead and the product operations lead, who often interfaced with clients were very interested. The other team that we found support from was our revenue cycle product team, which was mainly concerned with getting payments from payers, but was starting to see the trend of more payments being made by patients due to the popularity of high deductible insurance plans. These teams so opportunity in the problem space and building these relationships helped us find more ways to express the importance of the work to product leadership. These partners also provided a sounding board of internal subject matter experts and helped us design a stronger path towards a solution. "
                />

                <ProjectSection
                    title="Showing the Possibilities "
                    variant="colCopyStart"
                    hasImage
                    image={code}
                    imageAlt="coding prototype"
                    body="Even with a compelling story of the problem and a clear business value, there was still push back. The main issues were around the barriers, like the need to staff more teams, the complexity around payment gateways, limitations with the current code base, and the lack of mobile experience. To push the project forward, we needed to show that it was possible to create a minimum viable solution. We started out thinking about prototyping a mobile first, responsive payment site, but a set of animated screens wouldn’t fully show that a solution in a short amount of time was possible. Instead we decided to prototype with real code. We leveraged our new design system and newly selected front-end technology, React. I started taking React classes online and we started building. "
                />

                <ProjectSection
                    title="Collecting Feedback"
                    variant="rowCopyStart"
                    hasImage
                    image={qrcode}
                    imageAlt="qr code reader"
                    body="After a few weeks, we had put together a new mobile site, and we started collecting feedback from internal subject matter experts and possible users. We would quickly iterate based on the feedback and update the code. We found that lack of understanding of medical bills was a major reason behind patients waiting to pay their medical bills. Patients wanted to be sure that their insurance paid first to avoid overpaying. To help customers understand their bill we created an easy to read summary of the bill with options for more explanation and to review the details. We also identified other usability issues like having to enter a 15 character code found on the paper statement to login. To help with this we added a QR reader to the prototype and experimented with an optical character recognition (OCR) engine as alternatives to entering a code that also didn’t require a lot of changes to the paper statement containing the current code."
                />

                <ProjectSection
                    title="Influencing the Roadmap"
                    variant="colCopyStart"
                    hasImage
                    image={paymentplan}
                    imageAlt="payment plan options"
                    body="We looked for ways to improve how real the prototype was like using a mock database instead of hard coding information. We put information from a mock statement in the database and connected our prototype to it once the user entered the code or scanned the QR code on the mock statement. We also hosted the prototype using Google Firebase, so we could easily share it along with the mock statement and allow others to play with it on their phones and computers. Allowing people to play with the prototype on their phones was a game changer in terms of showing the possibilities and getting people to believe that a minimum viable product was feasible in the short-term. The prototype transitioned to a proof of concept. It also allowed us to show the future strategy of adding much needed functionality like language switching and simple payment plans. We were able to experiment, get feedback, and influence the priority of what should happen next once the minimal viable product was launched. "
                />

                <ProjectSection
                    title="The Outcome"
                    variant="colCopyStart"
                    hasImage
                    image={beta}
                    imageAlt="beta app"
                    body="Eventually the project was fully funded and I was tasked with being the experience design lead for the teams focused on building out our consumer health experiences. The proof of concept was passed to the development team as a starting point. I was able to work with the developers, product owner, and designer taking on the project. Through continued pushing, we were able to launch a beta with about two months of development work. Within 2 weeks of the release, the beta had processed over 40,000 transactions and netted almost 2 million in revenue and it continues to grow. "
                />
            </div>
        );
    }
}

export default PatientPayments;